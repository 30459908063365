<template>
<div>
  <!--TITULOS  -->
  <div class="air__utils__heading"> <h5>Formulación </h5> </div>
  <my-breadcrumbs :routes="routes"/>
  <!--BOTONERA  -->
  <div class="row justify-content-end mr-2">
    <b-button-group>
      <b-button  variant="primary" pill :to="{name: 'production_order_scheduling'}">
        <div class="d-none d-md-block" v-b-tooltip.hover title="Programar Orden de Producción">
          <b-icon icon="plus"/> Programar Orden de Producción
        </div>
        <div class="d-md-none" v-b-tooltip.hover title="Programar Orden de Producción">
          <b-icon icon="plus"/>
        </div>
      </b-button>
    </b-button-group>
  </div>
  <!--BOTONERA  -->
  <!--FILTROS  -->
  <a-row class="mb-4 mt-4" align="middle" :gutter="[8,24]">
    <!--Planta-->
    <a-col :sm="24" :lg="8">
      <antGlobalPlantFilter v-model="filterPlant" @change="FetchTable()"/>
    </a-col>
    <!--formulationTypes-->
    <a-col :sm="24" :md="12" :lg="8">
      <ResourceSelect v-model="filterFormulation" @change="FetchTable()"
                      urlResource="/formulationTypes"
                      :filters="formulationParams"
                      placeholder="Seleccionar Formulación"
      >
        <span slot-scope="{option}">{{option.name}}</span>
      </ResourceSelect>
    </a-col>
    <!--formulationTypes-->
    <a-col :sm="24" :md="12" :lg="8">
      <ResourceSelect v-model="filterShift"
                      @change="FetchTable()"
                      urlResource="/operationShifts"
                      :filters="shiftParams"
                      placeholder="Seleccionar Turno"
      >
        <span slot-scope="{option}">
          {{ option.name }}
          ({{ moment(option.initial_time).format('HH:mm') }} -
          {{ moment(option.final_time).format('HH:mm') }} )
        </span>
      </ResourceSelect>
    </a-col>
    <a-col :xs="24" :sm="12" :md="12">
      <a-date-picker  v-model="filterFrom" @change="FetchTable()"
                      placeholder="Período inicial" style="width: 100%"
                      format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
    </a-col>
    <a-col :xs="24" :sm="12" :md="12">
      <a-date-picker  v-model="filterTo" @change="FetchTable()"
                      placeholder="Período final" style="width: 100%"
                      format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
    </a-col>
  </a-row>
  <!--FILTROS  -->
  <!--TABLA  -->
  <!--TABLA-->
  <a-table class="mt-4"  :scroll="{x:1300}"
            :columns="tableObj.columns"
            :data-source="tableObj.data"
            :pagination="tableObj.pagination"
            :loading="tableObj.loading"
            @change="FetchTable"
            :row-key="record => record.id" bordered>
    <template slot="info" slot-scope="data">
      <formulationDescription :formulation="data"/>
    </template>
    <template slot="action" slot-scope="data">
      <b-button :to="{name: 'production_order_scheduling', params: {id: data.id}}"
        class="bg-real-blue border-real-blue" size="sm" pill>
        <b-icon icon="pencil-square"></b-icon>
      </b-button>
    </template>
  </a-table>
  <!--TABLA-->
</div>
</template>

<script>
// temporal data
import { fractalMixin } from '@/mixins/fractalMixin'
import { mapActions, mapState } from 'vuex'
import formulationDescription from './components/formulationDescription'
import { table } from './sample_data/produtionOrder'
export default {
  name: 'formulation',
  mixins: [fractalMixin],
  components: {
    formulationDescription,
  },
  data() {
    return {
      // temp data
      tableExample: table,
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'operations',
          breadcrumbName: 'Operaciones',
        },
        {
          breadcrumbName: 'Proceso Operativo',
          name: 'operation_process',
        },
        {
          breadcrumbName: 'Formulación',
        },
      ],
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'info' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            width: '10%',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      // Filtros
      filterFormulation: undefined,
      filterShift: undefined,
      filterFrom: '',
      filterTo: '',
      // select params
      formulationParams: {},
      // Filtros
    }
  },
  computed: {
    ...mapState('productionOrders', ['selectedWC']),
    shiftParams() {
      return {
        sort: 'name',
        'filter[workCenters]': this.selectedWC,
      }
    },
    filterPlant: {
      get() {
        return this.selectedWC
      },
      set(value) {
        this.SAVE_WORK_CENTER(value)
      },
    },
  },
  methods: {
    ...mapActions('productionOrders', ['SAVE_WORK_CENTER', 'RESET_WORK_CENTER']),
    async FetchTable(pagEvt) {
      const params = {
        include: 'operationShifts,formulationTypes,processTypes,technicalPrimes,technicalSecondaries,workCenters',
      }
      if (this.selectedWC) params['filter[workCenters]'] = this.selectedWC
      if (this.filterShift) params['filter[operationShifts]'] = this.filterShift
      if (this.filterFormulation) params['filter[formulationTypes]'] = this.filterFormulation
      if (this.filterFrom && this.filterTo) params['filter[periodBetween]'] = `${this.filterFrom},${this.filterTo}`
      await this.GetTableResource(pagEvt, 'formulations', this.tableObj, params)
    },
  },
  async mounted() {
    this.RESET_WORK_CENTER()
    // this.tableObj.data.push(this.tableExample)
    await this.FetchTable()
  },
}
</script>

<style scoped>

</style>
