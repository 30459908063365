
export const table = {
  id: 1,
  code: '45353425GSFDG',
  operationShifts: { code: 'operationShifts' },
  formulationType: { code: 'formulationType' },
  from: '2020/01/31',
  to: '2020/12/31',
  hours: '65',
  total: 55,
  container_quantity: 4,
  status: 'Abierta',
}

export const form = {
  code: '',
  operationShifts: undefined,
  formulationTypes: undefined,
  processTypes: undefined,
  from: '',
  to: '',
  hours: 0,
  total: 0,
  container_quantity: 0,
  status: 'Abierta',
  technical_one: '',
  technical_two: '',
}
